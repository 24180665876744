import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import styles from "./Drivers.module.css";
import { FiArrowLeft } from "react-icons/fi";
import userImg from "../../../../assets/Images/user.png";
import { Rating } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { acceptJob, getDriversLists } from "../../../../store/slices/createJob";
import Toster from "../../../../utils/Toaster";
import { STATUS_MSG } from "../../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../../utils/StatusCode";
import Loader from "../../../../utils/Loader/Loader";
import StarIcon from "@mui/icons-material/Star";

export default function DriverDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const jobData = useSelector((state) => state.createJob);
  const { first, second, third, fourth, fifth } = useSelector(
    (state) => state.createJob.createJobData
  );
  const storedCoupon = useSelector((state) => state.createJob.selectedCoupon);
  const { userToken } = useSelector((state) => state.user);

  const [driverInfo, setDriverInfo] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [driverReviews, setDriverReviews] = useState([]);

  const isSendFloorType = (type) => {
    if (jobData.activeCategory == "mbk5ez") {
      if (type == "source") {
        if (
          first.propertTypesHide.includes(first.propertyType.sourceProperty.val)
        ) {
          return false;
        } else {
          return true;
        }
      }
      if (type == "destination") {
        if (
          first.propertTypesHide.includes(
            first.propertyType.destinationProperty.val
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    var mergedAllItems;

    if (third.isSubmit && second.isSubmit) {
      mergedAllItems = [...second.allItems, ...third?.customItems];
    } else if (third.isSubmit) {
      mergedAllItems = third?.customItems;
    } else if (second.isSubmit) {
      mergedAllItems = second.allItems;
    }

    const transformedData = mergedAllItems.map((obj) => ({
      category_id: obj.categoryId,
      item_id: obj.id,
      item_name: obj.name,
      quantity: obj.quantity,
      width: obj.width,
      height: obj.height,
      depth: obj.depth,
      unit_id: obj.unit.id,
      estimated_weight: obj.weight,
      estimated_weight_unit_id: obj.weightUnit.id,
      is_custom_item: obj.is_custom_item,
    }));

    const transformedStops = first.stops.map((obj) => ({
      location: obj.location,
      latitude: obj.lat,
      longitude: obj.lng,
    }));

    let requestData = new FormData();
    requestData.append("category_type_id", jobData.activeCategory);
    requestData.append("source_location", first.sourceLocation);
    requestData.append("source_latitude", first.source_latitude);
    requestData.append("source_longitude", first.source_longitude?.toFixed(6));
    requestData.append("source_floor_id", first.sourceFloor.id);
    requestData.append("is_source_lift", first.hasSourceLift);
    requestData.append("destination_location", first.destinationLocation);
    requestData.append("destination_latitude", first.destination_latitude);
    requestData.append(
      "destination_longitude",
      first.destination_longitude?.toFixed(6)
    );

    requestData.append("destination_floor_id", first.destinationFloor.id);
    requestData.append("is_destination_lift", first.hasDestinationLift);
    requestData.append("add_stop", JSON.stringify(transformedStops));
    requestData.append("category_json", JSON.stringify(transformedData));
    requestData.append("vehicle_type_id", fourth.selectedVehicle.id);
    requestData.append("helper_id", fifth.helper.id);
    requestData.append(
      "availability_date",
      dayjs(fifth.date).format("YYYY-MM-DD")
    );
    requestData.append(
      "availability_time",
      dayjs(fifth.time).format("HH:mm:ss")
    );
    requestData.append("is_packing", fifth.isPacking);
    requestData.append("estimated_time", fifth.estimatedJobTimeMinutes);
    requestData.append("total_distance", fifth.totalDistance);
    requestData.append("id", id);
    storedCoupon && requestData.append("coupon_code", storedCoupon.coupon_code);
    requestData.append("page_no", pageNo);
    requestData.append(
      "source_property_type_id",
      first.propertyType.sourceProperty.id
    );
    requestData.append(
      "destination_property_type_id",
      first.propertyType.destinationProperty.id
    );

    dispatch(getDriversLists(requestData)).then((responseJson) => {
      const response = responseJson.payload;
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setIsLoadMore(response.data.loadMore);
        setDriverInfo(response.data);
        if (isLoadMore) {
          setDriverReviews((pre) => [...pre, response.data.reviews].flat());
        } else {
          setDriverReviews(response.data.reviews);
        }
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
  }, [pageNo]);

  const onBack = () => {
    navigate(-1);
  };

  const handelAcceptJob = (amount) => {
    var mergedAllItems;

    if (third.isSubmit && second.isSubmit) {
      mergedAllItems = [...second.allItems, ...third?.customItems];
    } else if (third.isSubmit) {
      mergedAllItems = third?.customItems;
    } else if (second.isSubmit) {
      mergedAllItems = second.allItems;
    }

    const transformedData = mergedAllItems.map((obj) => ({
      category_id: obj.is_custom_item ? "" : obj.categoryId,
      item_id: obj.is_custom_item ? "" : obj.id,
      item_name: obj.name,
      quantity: obj.quantity,
      width: obj.width,
      height: obj.height,
      depth: obj.depth,
      unit_id: obj.unit.id,
      estimated_weight: obj.weight,
      estimated_weight_unit_id: obj.weightUnit.id,
      is_custom_item: obj.is_custom_item,
    }));

    const transformedStops = first.stops.map((obj) => ({
      location: obj.location,
      latitude: obj.lat,
      longitude: obj.lng,
    }));

    let requestData = new FormData();

    requestData.append("category_type_id", jobData.activeCategory);
    requestData.append("source_location", first.sourceLocation);
    requestData.append("source_latitude", first.source_latitude);
    requestData.append(
      "source_longitude",
      first?.source_longitude && first.source_longitude?.toFixed(6)
    );
    requestData.append("source_city", first.source_city);
    requestData.append("source_postal_code", first.source_postalcode);

    // requestData.append("source_floor_id", first.sourceFloor.id);
    {
      isSendFloorType("source")
        ? requestData.append("source_floor_id", first.sourceFloor.id)
        : requestData.append("source_floor_id", "");
    }
    requestData.append("is_source_lift", first.hasSourceLift);
    requestData.append("destination_location", first.destinationLocation);
    requestData.append("destination_latitude", first.destination_latitude);
    requestData.append(
      "destination_longitude",
      first.destination_longitude && first.destination_longitude?.toFixed(6)
    );
    requestData.append("destination_city", first.destination_city);
    requestData.append("destination_postal_code", first.destination_postalcode);
    // requestData.append("destination_floor_id", first.destinationFloor.id);
    {
      isSendFloorType("destination")
        ? requestData.append("destination_floor_id", first.destinationFloor.id)
        : requestData.append("destination_floor_id", "");
    }
    requestData.append("is_destination_lift", first.hasDestinationLift);
    requestData.append("add_stop", JSON.stringify(transformedStops));
    requestData.append("category_json", JSON.stringify(transformedData));
    requestData.append("vehicle_type_id", fourth.selectedVehicle.id);
    requestData.append("helper_id", fifth.helper.id);
    requestData.append(
      "availability_date",
      dayjs(fifth.date).format("YYYY-MM-DD")
    );
    requestData.append(
      "availability_time",
      dayjs(fifth.time).format("HH:mm:ss")
    );
    requestData.append("estimated_time", fifth.estimatedJobTimeMinutes);
    requestData.append("is_packing", fifth.isPacking);
    requestData.append("driver_id", id);
    requestData.append("amount", driverInfo.amount);
    requestData.append("total_distance", fifth.totalDistance);
    storedCoupon && requestData.append("coupon_code", storedCoupon.coupon_code);
    requestData.append(
      "source_property_type_id",
      first.propertyType.sourceProperty.id
    );
    requestData.append(
      "destination_property_type_id",
      first.propertyType.destinationProperty.id
    );

    const data = { requestData, userToken };

    dispatch(acceptJob(data)).then((responseJson) => {
      const response = responseJson.payload;
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        const data = {
          responseData: response.data,
          driverDetails: driverInfo,
        };
        navigate("/payment", {
          state: data,
        });
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
  };

  const handleViewMore = () => {
    setPageNo((pre) => pre + 1);
  };

  return (
    <>
      {jobData.isLoading && <Loader />}
      <div className="main">
        <div className="spaceTopManage">
          <Row className={styles.topHead}>
            <Col lg={5} md={5} sm={5} xs={3}>
              <div className="backarrow">
                <FiArrowLeft onClick={() => onBack()} />
              </div>
            </Col>
            <Col lg={7} md={7} sm={7} xs={9}>
              <div>
                <span className={styles.headTitle}>{t("DRIVER_DETAILS")}</span>
              </div>
            </Col>
          </Row>
          {driverInfo && (
            <div className={styles.detailsWrapper}>
              <div className={styles.detailsContainer}>
                <div className={styles.profileSection}>
                  <div className={styles.driverBookedDetail}>
                    <div className={styles.driverJobBook}>
                      {driverInfo.jobs_booked}
                    </div>
                    <div className={styles.driverJobBookText}>
                      {t("JOBS_BOOKED")}
                    </div>
                  </div>
                  <div className={styles.profileInfo}>
                    <div>
                      <img
                        src={driverInfo.image}
                        className={styles.driverImageDetail}
                        alt="star-icon"
                      />
                    </div>
                    <div className={styles.profileName}>
                      {driverInfo.first_name} {driverInfo.last_name}
                    </div>
                    <div className={styles.profileEmail}>
                      {driverInfo.email}
                    </div>
                    <div className={styles.profileNumber}>
                      {driverInfo.dial_code} {driverInfo.phone_number}
                    </div>
                    <div className={styles.vanType}>
                      {driverInfo.vehicle_name}
                    </div>
                    {driverInfo.discount !== 0 && (
                      <div className={styles.driverDetailPriceCancel}>
                        {driverInfo.currency_symbol}{" "}
                        <strike>{driverInfo.amount}</strike>
                      </div>
                    )}
                    <div className={styles.driverDetailPrice}>
                      {driverInfo.currency_symbol}{" "}
                      {driverInfo &&
                        Number(driverInfo.total_amount)?.toFixed(2)}
                    </div>
                    <div className={styles.driverDetailExtraHours}>
                      For the first{" "}
                      <strong>
                        {fifth.estimatedJobTime.hour}:
                        {Number(fifth.estimatedJobTime.min)?.toFixed()} hours
                      </strong>{" "}
                      and then{" "}
                      <strong>
                        {driverInfo.currency_symbol}
                        {driverInfo.extra_per_hours_price}
                      </strong>{" "}
                      per hour
                    </div>
                  </div>
                  <div className={styles.profileRating}>
                    <div>
                      <Rating
                        className={styles.ratingStarIcons}
                        name="read-only"
                        value={driverInfo.rating.average_rating}
                        precision={0.5}
                        readOnly
                        icon={<StarIcon className={styles.ratingStarIcons} />}
                      />
                    </div>
                    <div className={styles.ratingText}>
                      {`(${driverInfo.rating.average_rating?.toFixed(1)}) ${
                        driverInfo.rating.rating_count
                      } ${
                        driverInfo.rating.rating_count > 1
                          ? t("REVIEWS")
                          : t("REVIEW")
                      }`}
                    </div>
                  </div>
                  <div className={styles.driverReviewDetails}>
                    <div className={styles.driverBookedDetails}>
                      <div className={styles.driverJobBook}>
                        {driverInfo.jobs_booked}
                      </div>
                      <div className={styles.driverJobBookText}>
                        {t("JOBS_BOOKED")}
                      </div>
                    </div>
                    <div className={styles.profileRatings}>
                      <div>
                        <Rating
                          className={styles.ratingStarIcons}
                          name="read-only"
                          value={driverInfo.rating.average_rating}
                          precision={0.5}
                          readOnly
                          icon={<StarIcon className={styles.ratingStarIcons} />}
                        />
                      </div>
                      <div className={styles.ratingText}>
                        {`(${driverInfo.rating.average_rating?.toFixed(1)}) ${
                          driverInfo.rating.rating_count
                        } ${
                          driverInfo.rating.rating_count > 1
                            ? t("REVIEWS")
                            : t("REVIEW")
                        }`}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.ratingsContainer}>
                  <div className={styles.ratingsHeading}>{t("RATINGS")}</div>
                  {driverReviews.length !== 0 &&
                    driverReviews.map((reviews, ind, arr) => {
                      return (
                        <>
                          <div className={styles.mainReviewProfile}>
                            <div className={styles.reviewProfileTop}>
                              <div className={styles.reviewProfile}>
                                <div className={styles.reviewImage}>
                                  <img
                                    src={
                                      reviews.profile_image
                                        ? reviews.profile_image
                                        : userImg
                                    }
                                    alt="star-icon"
                                  />
                                </div>
                                <div>
                                  <div className={styles.reviewerName}>
                                    {reviews.first_name} {reviews.last_name}
                                  </div>
                                  <div className={styles.reviewerEmail}>
                                    {reviews.email}
                                  </div>
                                  <div className={styles.reviewDate}>
                                    {dayjs(reviews.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className={styles.reviewText}>
                                {reviews.review}
                              </div>
                            </div>
                            <div className={styles.reviewStarRating}>
                              <Rating
                                className={styles.ratingStarIcons}
                                name="read-only"
                                value={reviews.rating}
                                precision={0.5}
                                readOnly
                                icon={
                                  <StarIcon
                                    className={styles.ratingStarIcons}
                                  />
                                }
                              />
                            </div>
                          </div>
                          <div className={styles.lineBar}>
                            <hr className={styles.line} />
                            {arr.length == ind + 1 && isLoadMore && (
                              <span
                                className={styles.viewMoreBtn}
                                onClick={() => handleViewMore()}
                              >
                                {t("VIEW_MORE")}
                              </span>
                            )}
                            <hr className={styles.line} />
                          </div>
                        </>
                      );
                    })}
                  {driverInfo.reviews &&
                    driverInfo.reviews.length == 0 &&
                    t("NOT_AVAILABLE")}
                  <div className={styles.acceptBtnFooter}>
                    <Button onClick={() => handelAcceptJob()}>
                      {t("ACCEPT")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
